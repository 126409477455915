import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import CaseStudyText from "../../screens/resources/case-study/sti-female-patient-pam"

const CaseStudy = () => {
    return (
    <React.Fragment>
      <CaseStudyText />
    </React.Fragment>
  )
}
export default CaseStudy

export const Head = () => (
  <Seo
        title="Patient Case Study 3: Overtreatment of STIs"
        description="Pam, a divorced 46-year-old woman, presents to the clinic with complaints of malodorous vaginal discharge and itching."
        image="/meta/case-study-pam.jpg"
  />
)
